import "@hotwired/turbo-rails"
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { library } from "@fortawesome/fontawesome-svg-core";
import '@fortawesome/fontawesome-free'
library.add(fas, far, fab)

document.addEventListener("turbo:click", function(event) {
  const transitionMessage = event.target.getAttribute("data-transition-message");
  if(transitionMessage) {
    document.querySelector('.modal-text').textContent = transitionMessage;
  }
})

document.addEventListener("turbo:before-fetch-request", function(event) {
  document.querySelector(".loading-modal").classList.remove("hidden")
})

document.addEventListener("turbo:before-render", function() {
  document.querySelector(".loading-modal").classList.add("hidden")
})